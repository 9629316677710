textarea {
	font-size: small;
}
#stateReport {
	width: 460px;
	height: 550px;
}
#streamStatus {
	width: 350px;
	height: 120px;
}
#sessionStatus {
	width: 350px;
	height: 120px;
}